import {
    createApiFactory,
    createPlugin,
    createRoutableExtension
} from "@backstage/core-plugin-api";

import {K8sDashboardApiRef, K8sDashboardClient} from "./apis";
import {pluginId, rootRouteRef} from "./routes";

export const K8sDashboardPlugin = createPlugin({
    id: pluginId,
    routes: {
        root: rootRouteRef,
    },
    apis: [
        createApiFactory({
            api: K8sDashboardApiRef,
            deps: {},
            factory: () =>
                new K8sDashboardClient(),
        })
    ]
});

export const K8sDashboardPage = K8sDashboardPlugin.provide(
    createRoutableExtension({
        name: "K8sDashboard",
        component: () =>
            import("./components/K8sDashboardPage").then(m => m.K8sDashboardPage),
        mountPoint: rootRouteRef,
    }),
);
