import {
    createApiRef,
    useApi
} from "@backstage/core-plugin-api";
import { KubernetesApi,kubernetesApiRef } from "@backstage/plugin-kubernetes";

import { ResourceTypes } from "./resourceTypes";
import {pluginId} from "./routes";

export const K8sDashboardApiRef = createApiRef<K8sDashboardApi>({
    id: pluginId,
});

export interface K8sDashboardApi {
    fetchClusters(): Promise<[any]>;
    fetchK8sData(clusterName:string, resourceType:keyof typeof ResourceTypes, namespace?: string): Promise<any>;
}

export class K8sDashboardClient {
    private readonly kubernetesApi: KubernetesApi;

    constructor() {
        this.kubernetesApi = useApi(kubernetesApiRef);
    }

    async fetchClusters(): Promise<any> {
        return (await(await this.kubernetesApi.getClusters()));
    }

    async fetchK8sData(clusterName:string, resourceType:keyof typeof ResourceTypes, namespace?: string): Promise<any> {
        const path:string = ResourceTypes[resourceType].path && ResourceTypes[resourceType].path(namespace) || "";

        return new Promise<any>((resolve, reject) => this.kubernetesApi.proxy({clusterName, path})
            .then((res:Response) => {
                if (res?.status !== 200) {
                    return reject("Failed to fetch data from cluster.");
                }
                return res.json();
            })
            .then((json:any) => {
                const items:[any] = json?.items || [];
                return resolve(items);
            })
        );
    }
}
