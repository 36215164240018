import {
    createApiFactory,
    createPlugin,
    createRoutableExtension,
    discoveryApiRef,
    fetchApiRef,
    identityApiRef,
} from "@backstage/core-plugin-api";

import {FireChiefApiRef, FireChiefClient} from "./apis";
import {pluginId, rootRouteRef} from "./routes";

export const FireChiefPlugin = createPlugin({
    id: pluginId,
    routes: {
        root: rootRouteRef,
    },
    apis: [
        createApiFactory({
            api: FireChiefApiRef,
            deps: {
                identityApi: identityApiRef,
                discoveryApi: discoveryApiRef,
                fetchApi: fetchApiRef,
            },
            factory: ({identityApi, discoveryApi, fetchApi}) =>
                new FireChiefClient({identityApi, discoveryApi, fetchApi}),
        })
    ]
});

export const FireChiefPage = FireChiefPlugin.provide(
    createRoutableExtension({
        name: "FireChiefPage",
        component: () =>
            import("./components/FireChiefPage").then(m => m.FireChiefPage),
        mountPoint: rootRouteRef,
    }),
);
