import React from "react";

import {Content, DashboardIcon, GitHubIcon, HelpIcon, Page} from "@backstage/core-components";
import {
    HomePageCompanyLogo,
    HomePageRecentlyVisited,
    HomePageStarredEntities,
    HomePageToolkit,
    HomePageTopVisited,
    TemplateBackstageLogo,
} from "@backstage/plugin-home";
import {HomePageSearchBar} from "@backstage/plugin-search";
import {SearchContextProvider} from "@backstage/plugin-search-react";
import {Grid, makeStyles} from "@material-ui/core";

import LogoIcon from "../Root/LogoIcon";

const useStyles = makeStyles(theme => ({
    searchBarInput: {
        maxWidth: "60vw",
        margin: "auto",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "50px",
        boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
        borderStyle: "none"
    }
}));

const useLogoStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(5, 0),
    },
    svg: {
        width: "auto",
        height: 100,
    },
    path: {
        fill: "#7df3e1",
    },
}));

export const HomePage = () => {
    const classes = useStyles();
    const {svg, path, container} = useLogoStyles();

    return (
        <SearchContextProvider>
            <Page themeId="home">
                <Content>
                    <Grid container justifyContent="center" spacing={6}>
                        <HomePageCompanyLogo
                            className={container}
                            logo={<TemplateBackstageLogo classes={{svg, path}}/>}
                        />
                        <Grid container item xs={12} justifyContent='center'>
                            <HomePageSearchBar
                                InputProps={{
                                    classes: {
                                        root: classes.searchBarInput,
                                        notchedOutline: classes.searchBarOutline
                                    }
                                }}
                                placeholder="Search"
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12} md={6}>
                                <HomePageToolkit
                                    tools={[
                                        {
                                            url: "https://backstage.io",
                                            label: "Backstage",
                                            icon: <LogoIcon/>,
                                        },
                                        {
                                            url: "https://bep.jackhenry.com",
                                            label: "Jack Henry Portal",
                                            icon: <DashboardIcon/>,
                                        },
                                        {
                                            url: "https://github.com/Banno",
                                            label: "Banno GitHub",
                                            icon: <GitHubIcon/>,
                                        },
                                        {
                                            url: "https://banno.slack.com/archives/C045TAQ0FA9",
                                            label: "Team Nebula",
                                            icon: <HelpIcon/>,
                                        },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HomePageStarredEntities/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HomePageTopVisited/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HomePageRecentlyVisited/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Content>
            </Page>
        </SearchContextProvider>
    );
};
