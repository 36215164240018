import {
    createApiFactory,
    createPlugin,
    createRoutableExtension,
    discoveryApiRef,
    fetchApiRef,
} from "@backstage/core-plugin-api";

import {OneShotSqlClient,OneShotSqlRef} from "./apis";
import { rootRouteRef } from "./routes";

export const oneshotsqlPlugin = createPlugin({
    id: "oneshotsql",
    routes: {
        root: rootRouteRef,
    },
    apis: [
        createApiFactory({
            api: OneShotSqlRef,
            deps: {
                discoveryApi: discoveryApiRef,
                fetchApi: fetchApiRef,
            },
            factory: ({discoveryApi, fetchApi}) =>
                new OneShotSqlClient({discoveryApi, fetchApi}),
        })
    ]
});

export const OneshotsqlPage = oneshotsqlPlugin.provide(
    createRoutableExtension({
        name: "OneshotsqlPage",
        component: () =>
            import("./components/OneShotSqlPage").then(m => m.OneShotSqlPage),
        mountPoint: rootRouteRef,
    }),
);
