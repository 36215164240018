import {
    createApiRef,
    DiscoveryApi,
    FetchApi,
    IdentityApi,
    ProfileInfo
} from "@backstage/core-plugin-api";

import {pluginId} from "./routes";

export const FireChiefApiRef = createApiRef<FireChiefApi>({
    id: pluginId,
});

export interface FireChiefApi {
    fetchFireFighters(): Promise<Response>;
    fetchProfile(): Promise<ProfileInfo>;
    fetchIsFireFighter(): Promise<Response>;
    registerAsFireFighter(): Promise<Response>;
    deregisterAsFireFighter(): Promise<Response>;
}

export class FireChiefClient {
    private readonly discoveryApi: DiscoveryApi;
    private readonly fetchApi: FetchApi;
    private readonly identityApi: IdentityApi;

    constructor(options: {
        discoveryApi: DiscoveryApi;
        fetchApi: FetchApi;
        identityApi: IdentityApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.fetchApi = options.fetchApi;
        this.identityApi = options.identityApi;
    }

    async fetchProfile(): Promise<ProfileInfo> {
        return await this.identityApi.getProfileInfo();
    }

    async fetchIsFireFighter(): Promise<Response> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi.fetch(`${baseUrl}/banno/isfirefighter`);
    }

    async fetchFireFighters(): Promise<Response> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi.fetch(`${baseUrl}/banno/firefighters`);
    }

    async registerAsFireFighter(): Promise<Response> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi.fetch(`${baseUrl}/banno/registerasfirefighter`, {
            method: "POST"
        });
    }

    async deregisterAsFireFighter(): Promise<Response> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi.fetch(`${baseUrl}/banno/deregisterasfirefighter`, {
            method: "POST"
        });
    }

}
