import {
    createApiRef,
    DiscoveryApi,
    FetchApi,
} from "@backstage/core-plugin-api";

const pluginId = "oneshotsql";

export const OneShotSqlRef = createApiRef<OneShotSqlApi>({
    id: pluginId,
});

export interface OneShotSqlApi {
    run(env: string, org: string, prLink: string, user: string): Promise<Response>;
    getHistory(): Promise<Response>;
}

export class OneShotSqlClient implements OneShotSqlApi{
    private readonly discoveryApi: DiscoveryApi;
    private readonly fetchApi: FetchApi;

    constructor(options: {
        discoveryApi: DiscoveryApi;
        fetchApi: FetchApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.fetchApi = options.fetchApi;
    }

    async run(env: string, org: string, prLink: string, requester: string): Promise<Response> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi.fetch(`${baseUrl}/run`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                env: env,
                org: org,
                prLink: prLink,
                requester: requester,
            })
        });
    }

    async getHistory(): Promise<Response> {
        const baseUrl = await this.discoveryApi.getBaseUrl(pluginId);
        return await this.fetchApi.fetch(`${baseUrl}/history`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
}
