import {
    createComponentExtension,
    createPlugin
} from "@backstage/core-plugin-api";

import {pluginId} from "./routes";

export const MiscPlugin = createPlugin({
    id: pluginId
});

export const Metadata = MiscPlugin.provide(
    createComponentExtension({
        component: {
            lazy: () => import("./components/Metadata").then(m => m.Metadata)
        },
        name: "Metadata"
    })
);
