import {
    createApiFactory,
    createPlugin,
    createRoutableExtension,
    discoveryApiRef,
    fetchApiRef,
} from "@backstage/core-plugin-api";
import {pluginId} from "@internal/backstage-plugin-secret-sharing-common";

import {secretSharingApiRef, SecretSharingClient} from "./components/apis";
import {rootRouteRef} from "./routes";

export const secretSharingPlugin = createPlugin({
    id: pluginId,
    routes: {
        root: rootRouteRef,
    },
    apis: [
        createApiFactory({
            api: secretSharingApiRef,
            deps: {
                discoveryApi: discoveryApiRef,
                fetchApi: fetchApiRef,
            },
            factory: ({discoveryApi, fetchApi}) =>
                new SecretSharingClient({discoveryApi, fetchApi}),
        })
    ]
});

export const SecretSharingPage = secretSharingPlugin.provide(
    createRoutableExtension({
        name: "SecretSharingPage",
        component: () =>
            import("./components/SecretSharingPage").then(m => m.SecretSharingPage),
        mountPoint: rootRouteRef,
    }),
);
